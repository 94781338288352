import { app } from '../main.ts';
import {createStore} from 'vuex';
import ble from './ble';

export default new createStore({
  modules: {
    ble: ble
  },
  state() {
    return {
      user: null,
      notificationsCounter: 0,
      myProfileCounter: 0,
      myProfileNotifications: {},
      invitationsCounter: 0,
      translations: {},
      featureFlags: {},
      heartRate: 0,
      deviceId: null,
      devices: []
    }
  },
  mutations: {
    setUser(state, value) {
      state.user = value
      if (value && value.full_name && value.email) {
        app.config.globalProperties.$tracking.setUser({
          email: value.email,
          plan: value.stripe_products_slug ?? 'standard',
          createdAt: value.created_at ?? null,
          fullName: value.full_name ?? '',
          cognitoId: value.cognito_id ?? null,
          clubId: value.club_id ?? null
        })
      } else {
        app.config.globalProperties.$tracking.clearUser()
      }
    },
    setUserMhr(state, value) {
      state.user.max_heart_rate = value
    },
    setUserRhr(state, value) {
      state.user.resting_heart_rate = value
    },
    setNotificationsCounter(state, value) {
      state.notificationsCounter = value
    },
    setMyProfileCounter(state, value) {
      state.myProfileCounter = value
    },
    setInvitationsCounter(state, value) {
      state.invitationsCounter = value
    },
    setTranslations(state, value) {
      state.translations = value
    },
    setFeatureFlags(state, value) {
      state.featureFlags = value
      if(value['sentry_logs'] ?? false) {
        app.config.globalProperties.$tracking.init()
      }
    },
    setHeartRate(state, value) {
      state.heartRate = value
    },
    setMyProfileNotifications(state, value) {
      state.myProfileNotifications = value
    },
    setDeviceId(state, value) {
      state.deviceId = value
    },
    setDevices(state, value) {
      state.devices = value
    }
  }
})
