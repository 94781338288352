import i18n from '@/i18n';
import {
    IonAvatar,
    IonBadge, IonButton, IonButtons, IonChip, IonContent,
    IonDatetime,
    IonHeader,
    IonicVue,
    IonInput,
    IonItem, IonItemDivider, IonItemGroup,
    IonLabel, IonList, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonNote,
    IonToggle
} from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/display.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/typography.css';
import { createApp } from 'vue';
import { default as App } from './App.vue';
import router from './router';
import store from './store';
/* Theme variables */
import './theme/variables.css';
// import { SplashScreen } from '@capacitor/splash-screen';
import { trackingPlugin } from './utils/logging'

export const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(store)
    .use(trackingPlugin)

app.config.globalProperties.__ = i18n;

app.component('IonBadge', IonBadge)
app.component('IonTitle', IonTitle)
app.component('IonToolbar', IonToolbar)
app.component('IonHeader', IonHeader)
app.component('IonAvatar', IonAvatar)
app.component('IonLabel', IonLabel)
app.component('IonInput', IonInput)
app.component('IonItem', IonItem)
app.component('IonDatetime', IonDatetime)
app.component('IonSelect', IonSelect)
app.component('IonSelectOption', IonSelectOption)
app.component('IonButton', IonButton)
app.component('IonContent', IonContent)
app.component('IonChip', IonChip)
app.component('IonItemDivider', IonItemDivider)
app.component('IonItemGroup', IonItemGroup)
app.component('IonButtons', IonButtons)
app.component('IonList', IonList)
app.component('IonBackButton', IonBackButton)
app.component('IonNote', IonNote)
app.component('IonToggle', IonToggle)

router.isReady().then(() => {
    app.mount('#app');
});