<template>
  <ion-app>
    <ion-router-outlet v-if="!loadingState"/>
  </ion-app>
</template>

<script>
import {IonApp, IonRouterOutlet, alertController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {API} from '@/utils/apiWrapper';
import {Network} from '@ionic-native/network';
import { Storage } from '@capacitor/storage';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  data() {
    return {
      loadingState: true,
      tsNetworkAlert: undefined
    }
  },
  async mounted() {
    API.get('NoAuth', '/init', {}).then(response => {
      this.$store.commit('setTranslations', response.data.translations)
      this.$store.commit('setFeatureFlags', response.data.feature_flags)
    }).finally(() => {
      this.loadingState = false
    })

    Network.onConnect().subscribe(() => {
      if (this.tsNetworkAlert) {
        this.tsNetworkAlert.dismiss()
      }
    });

    Network.onDisconnect().subscribe(async () => {
      this.tsNetworkAlert = await alertController.create({
        header: this.__('interface.you-are-offline'),
        message: this.__('interface.check-your-internet-connection'),
        backdropDismiss: false
      })
      await this.tsNetworkAlert.present()
    });
    const { value: migratedStorage } = await Storage.get({key: 'storage-migrated'})
    if (migratedStorage !== 'complete') {
      await Storage.migrate()
      await Storage.set({key: 'storage-migrated', value: 'complete'});
    }
  }
});
</script>