import {API} from '@/utils/apiWrapper'
import {Auth} from '@aws-amplify/auth'

export default {
  methods: {
    async checkAuth() {
      Auth.currentAuthenticatedUser().catch(() => {
        this.$router.push('/login')
      })
    },
    async checkNoAuth() {
      Auth.currentAuthenticatedUser().then(() => {
        this.$router.push('/tabs')
      }).catch(() => {
        console.warn('Auth Error')
      })
    },
    async user() {
      let user = {}

      await API.get('TheSpottApi', '/me', {}).then(response => {
        if (response.data !== null) {
          user = response.data
        }
      }).catch(() => {
        console.warn('Error getting user')
      })

      await Auth.currentUserInfo().then(async response => {
        user.email = response.attributes.email
      }).catch(() => {
        console.warn('Error getting current user info')
      })

      this.$store.commit('setUser', user);

      return user
    }
  }
}
