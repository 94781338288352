<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="sessions" href="/tabs/sessions">
          <ion-icon :icon="calendarOutline"/>
          <ion-label>{{ __('interface.sessions') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="history" href="/tabs/history">
          <ion-icon :icon="statsChartOutline"/>
          <ion-label>{{ __('interface.history') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="devices" href="/tabs/devices">
          <ion-icon :icon="watchOutline"/>
          <ion-label>{{ __('interface.devices') }}</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="clubs" href="/tabs/clubs">
          <ion-icon :icon="peopleOutline"/>
          <ion-label>{{ __('interface.clubs') }}</ion-label>
          <ion-badge v-if="invitationsCounter > 0" color="danger">{{ displayInvitationsCounter }}</ion-badge>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {alertController, IonIcon, IonPage, IonTabBar, IonTabButton, IonTabs} from '@ionic/vue';
import {
  calendarOutline,
  notificationsOutline,
  personOutline,
  watchOutline,
  statsChartOutline,
  peopleOutline
} from 'ionicons/icons';
import AuthService from '@/views/AuthService';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import {API} from '@/utils/apiWrapper';
import {AndroidPermissions} from '@ionic-native/android-permissions';
import {mapActions} from 'vuex';
import { PushNotifications } from '@capacitor/push-notifications';


export default {
  name: 'Tabs',
  mixins: [AuthService],
  components: {IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage},
  computed: {
    invitationsCounter() {
      return this.$store.state.invitationsCounter ?? 0
    },
    displayInvitationsCounter() {
      if (this.invitationsCounter > 9) {
        return '9+'
      }

      return this.invitationsCounter
    },
    route() {
      return this.$route ?? {}
    }
  },
  setup() {
    return {
      calendarOutline,
      watchOutline,
      personOutline,
      notificationsOutline,
      statsChartOutline,
      peopleOutline
    }
  },
  methods: {
    async updateCounters() {
      await API.get('TheSpottApi', '/notification-count', {}).then(response => {
        this.$store.commit('setNotificationsCounter', response.data.notifications.count ?? 0);
        this.$store.commit('setMyProfileCounter', response.data.myProfile.count ?? 0);
        this.$store.commit('setMyProfileNotifications', response.data.myProfile.notifications ?? {});
        this.$store.commit('setInvitationsCounter', response.data.invitations.count ?? 0);
      })
    },
    registerPush() {
      PushNotifications.requestPermissions().then((permission) => {
        if (permission.granted) {
          PushNotifications.register();
        }
      });

      PushNotifications.addListener(
        'registration',
        (token) => {
          const deviceId = token.value
          this.$store.commit('setDeviceId', deviceId)
          API.post('TheSpottApi', '/device', {
            body: {
              deviceId: deviceId
            }
          })
        }
      );

      PushNotifications.addListener(
        'pushNotificationReceived',
        async () => {
          await this.updateCounters()
        }
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        async () => {
          await this.$router.push({name: 'NotificationsIndex'})
        }
      );
    },
    async init() {
      await this.scan();
      setTimeout(async () => {
        await this.autoConnect();
      }, 5100);
    },
    ...mapActions({
      scan: 'ble/scan',
      autoConnect: 'ble/autoConnect',
    })
  },
  async ionViewDidEnter() {
    await this.user();

    if (Capacitor.platform !== 'web') {
      this.registerPush();
    }

    await this.updateCounters()
  },
  async mounted() {
    App.addListener('appStateChange', async ({isActive}) => {
      if (isActive) {
        await this.init();
      }
    })

    if (Capacitor.platform === 'ios') {
      await this.init();
    }

    if (Capacitor.platform === 'android') {
      AndroidPermissions.checkPermission(AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION).then(async result => {
        if (!result.hasPermission) {
          const alert = await alertController.create({
            header: this.__('interface.location-permissions'),
            message: this.__('interface.location-permissions-desc'),
            buttons: [{
              text: this.__('interface.ok'),
              handler: async () => {
                await this.init();
              }
            }]
          })
          await alert.present();
        } else {
          await this.init();
        }
      })
    }
  },
  watch: {
    route() {
      this.updateCounters();
    }
  }
}
</script>
