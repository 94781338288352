import store from '@/store'

export default function (key, ...args) {
  let trans = store.state.translations[key] ?? '$' + key

  args.forEach((arg, idx) => {
    trans = trans.replace(`{${idx}}`, arg)
  })

  return trans
}
