import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../layouts/Tabs.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/Authentication/Login.vue')
  },
  {
    path: '/register',
    component: () => import('@/views/Authentication/Register.vue')
  },
  {
    name: 'RegisterConfirmCode',
    path: '/register-confirm-code',
    component: () => import('@/views/Authentication/RegisterConfirmCode.vue')
  },
  {
    path: '/register-request-code',
    component: () => import('@/views/Authentication/RegisterRequestCode.vue')
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/Authentication/ForgotPassword.vue')
  },
  {
    path: '/forgot-password-code',
    component: () => import('@/views/Authentication/ForgotPasswordCode.vue')
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/tabs/sessions'
      },
      {
        path: 'history',
        name: 'History',
        component: () => import('@/views/History/History.vue'),
      },
      {
        name: 'SessionIndex',
        path: 'sessions',
        component: () => import('@/views/Sessions/Sessions.vue'),
      },
      {
        path: 'sessions/waiting-room/:sessionId/:ownerId/:contextDate',
        name: 'SessionWaitingRoom',
        component: () => import('@/views/VideoConferenceWaitingRoom.vue'),
      },
      {
        name: 'DevicesIndex',
        path: 'devices',
        component: () => import('@/views/DevicesIndex.vue'),
      },
      {
        path: 'notifications',
        name: 'NotificationsIndex',
        component: () => import('@/views/NotificationsIndex.vue'),
      },
      {
        path: 'clubs',
        name: 'ClubIndex',
        component: () => import('@/views/Club/ClubIndex.vue'),
      },
      {
        path: 'club/invite',
        name: 'ClubInvite',
        component: () => import('@/views/Club/ClubInvite.vue'),
      },
      {
        path: 'clubs/:id/trainers',
        component: () => import('@/views/Club/ClubViewTrainers.vue'),
      },
      {
        path: 'clubs/:clubId/trainers/:trainerId/message',
        component: () => import('@/views/Club/ClubViewTrainersMessage.vue'),
      },
      {
        path: 'clubs/:id/sessions',
        component: () => import('@/views/Club/ClubViewSessions.vue'),
      },
      {
        path: 'clubs/:id/history',
        component: () => import('@/views/Club/ClubViewHistory.vue'),
      },
      {
        path: 'clubs/:id',
        component: () => import('@/views/Club/ClubView.vue'),
      },
      {
        path: 'my-profile',
        name: 'MyProfile',
        component: () => import('@/views/MyProfile/Index.vue'),
      },
      {
        path: 'my-profile/personal-information',
        name: 'MyProfile/PersonalInformation',
        component: () => import('@/views/MyProfile/PersonalInformation.vue'),
      },
      {
        path: 'my-profile/change-password',
        name: 'MyProfile/ChangePassword',
        component: () => import('@/views/MyProfile/ChangePassword.vue'),
      },
      {
        path: 'my-profile/privacy',
        name: 'MyProfile/Privacy',
        component: () => import('@/views/MyProfile/Privacy.vue'),
      },
      {
        path: 'my-profile/resting-heart-rate',
        name: 'MyProfile/RestingHeartRate',
        component: () => import('@/views/MyProfile/RestingHeartRate/Index.vue'),
      },
      {
        path: 'my-profile/resting-heart-rate/measurement',
        name: 'MyProfile/RestingHeartRate/Measurement',
        component: () => import('@/views/MyProfile/RestingHeartRate/Measurement.vue'),
      },
      {
        path: 'my-profile/resting-heart-rate/manually',
        name: 'MyProfile/RestingHeartRate/Manually',
        component: () => import('@/views/MyProfile/RestingHeartRate/Manually.vue'),
      },
      {
        path: 'my-profile/max-heart-rate',
        name: 'MyProfile/MaxHeartRate',
        component: () => import('@/views/MyProfile/MaxHeartRate.vue'),
      },
      {
        path: 'history/summary/:id/:contextDate',
        name: 'HistorySummary',
        component: () => import('@/views/Sessions/SessionSummary.vue'),
      },
      {
        path: 'sessions/summary/:id/:contextDate',
        name: 'SessionSummary',
        component: () => import('@/views/Sessions/SessionSummary.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    redirect: '/tabs/'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
