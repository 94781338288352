import { app } from '../main.ts';
import ble from 'cordova-plugin-ble-central/www/ble';
import {Plugins} from '@capacitor/core';
import { Storage } from '@capacitor/storage';
const {TwilioCapacitor} = Plugins

const heartRateParams = {
  service: '180d',
  measurement: '2a37'
};

const batteryService = {
  service: '180f',
  measurement: '2a19'
};

export default {
  namespaced: true,
  state() {
    return {
      connectedDevice: null,
      lastConnectedDevice: null,
      myDevices: [],
      otherDevices: [],
      statuses: {},
      connectStatuses: {},
      loadingState: false,
      heartRate: 0,
      batteryLevel: 0
    }
  },
  actions: {
    async connect({state, dispatch}, device) {
      return new Promise((resolve, reject) => {
          const success = async () => {
            const existingInMyDevices = await dispatch('existingInMyDevices', device);

            if (!existingInMyDevices) {
              state.myDevices.push(device);
              await Storage.set({key: 'my-devices', value: JSON.stringify(state.myDevices)});
              const idx = state.otherDevices.indexOf(device);
              state.otherDevices.splice(idx, 1);
            }

            state.statuses[device.name] = true;
            state.connectedDevice = device;
            state.lastConnectedDevice = device;

            const batterySuccess = (response) => {
              const data = new Uint8Array(response);
              state.batteryLevel = data["0"];
            }

            // eslint-disable-next-line @typescript-eslint/no-empty-function
            const batteryFailure = () => {
            };

            await ble.read(device.id, batteryService.service, batteryService.measurement, batterySuccess, batteryFailure);
            app.config.globalProperties.$tracking.event({
              eventName: 'connected-device',
              category: 'devices'
            });
            resolve();
          };

          const failure = async () => {
            state.statuses[device.name] = false;
            state.connectedDevice = null;
            state.heartRate = 0;
            TwilioCapacitor.deviceDisconnected({message: 'message.pairing-error', deviceId: device.id})
            // TwilioCapacitor.deviceDisconnected({message: this.__('message.pairing-error'), deviceId: device.id})
            TwilioCapacitor.setHeartRate({heartRate: Number(state.heartRate)})
            app.config.globalProperties.$tracking.event({
              eventName: 'failed-connect-device',
              category: 'devices'
            });
            reject();
          };

          ble.connect(device.id, success, failure)
        }
      )
    },
    async reconnect({state, dispatch}) {
      if (state.lastConnectedDevice !== null) {
        await dispatch('connect', state.lastConnectedDevice).then(async () => {
          await dispatch('startNotification');
        })
      }
    },
    async disconnect({state}, device) {
      const success = async () => {
        state.statuses[device.name] = false
      };

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const failure = async () => {
      };

      await ble.disconnect(device.id, success, failure)
    },
    async autoConnect({state, dispatch}) {
      for (const device of state.myDevices) {
        if (state.connectStatuses[device.name]) {
          await dispatch('connect', device)
          break;
        }
      }
    },
    async scan({state, dispatch}) {
      state.otherDevices = [];
      state.connectStatuses = {};

      const isEnabledSuccess = async () => {
        const success = async (device) => {
          const existingInMyDevices = await dispatch('existingInMyDevices', device);

          if (!existingInMyDevices) {
            const existingInOtherDevices = state.otherDevices.find(otherDevice => {
              return otherDevice.name === device.name
            });

            if (!existingInOtherDevices) {
              state.otherDevices.push(device);
            }
          }

          state.connectStatuses[device.name] = true
        };

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const failure = async () => {
        };

        state.loadingState = true;
        await ble.scan([heartRateParams.service], 5, success, failure);
        app.config.globalProperties.$tracking.event({
          eventName: 'scan-devices',
          category: 'devices'
        });
        setTimeout(() => {
          state.myDevices.forEach(device => {
            ble.isConnected(device.id, () => {
              state.statuses[device.name] = true
            }, () => {
              state.statuses[device.name] = false
            })
          })

          state.otherDevices.forEach(device => {
            ble.isConnected(device.id, () => {
              state.statuses[device.name] = true
            }, () => {
              state.statuses[device.name] = false
            })
          });
          state.loadingState = false;
        }, 5000);
      }

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const isEnabledFailure = async () => {
      };

      ble.isEnabled(isEnabledSuccess, isEnabledFailure)

    },
    async remove({state, dispatch}, device) {
      await dispatch('disconnect', device);
      const idx = state.myDevices.indexOf(device);
      state.myDevices.splice(idx, 1);
      await Storage.set({key: 'my-devices', value: JSON.stringify(state.myDevices)});
    },
    async startNotification({state}) {
      if (state.connectedDevice !== null) {
        const success = async (res) => {
          const data = new Uint8Array(res);
          state.heartRate = data["1"];
          TwilioCapacitor.setHeartRate({heartRate: Number(state.heartRate)})
        };

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const failure = async () => {

        };

        await ble.startNotification(state.connectedDevice.id, heartRateParams.service, heartRateParams.measurement, success, failure);
      }
    },
    async stopNotification({state}) {
      if (state.connectedDevice !== null) {
        const success = async () => {
          state.heartRate = 0;
          TwilioCapacitor.setHeartRate({heartRate: Number(state.heartRate)})
        };

        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const failure = async () => {

        };

        await ble.stopNotification(state.connectedDevice.id, heartRateParams.service, heartRateParams.measurement, success, failure);
      }
    },
    async existingInMyDevices({state}, device) {
      const myDevicesJson = await Storage.get({key: 'my-devices'});
      state.myDevices = JSON.parse(myDevicesJson.value) ?? [];

      const findDevice = state.myDevices.find(myDevice => {
        return myDevice.name === device.name
      });

      // Update the device information after scanning (in some devices the id changes when the battery is removed).
      if (findDevice) {
        const idx = state.myDevices.indexOf(findDevice)
        state.myDevices[idx] = device;
        await Storage.set({key: 'my-devices', value: JSON.stringify(state.myDevices)});
      }

      return findDevice !== undefined;
    },
  }
}