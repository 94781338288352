import {Capacitor} from '@capacitor/core';
import {HTTP} from '@ionic-native/http';
import Amplify, {API as AwsAPI, Auth} from "aws-amplify";
import { app } from '../main.ts';

const apiEndpoint = process.env.VUE_APP_AMP_API ? process.env.VUE_APP_AMP_API : '/api';

async function getAuthorization() {
  return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
}

async function getHeaders(apiName) {
  const headers = {}

  if (apiName === 'TheSpottApi') {
    headers['Authorization'] = await getAuthorization()
    headers['Content-Type'] = 'application/json'
  }

  return headers
}

HTTP.setDataSerializer('json')

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "TheSpottApi",
        endpoint: apiEndpoint,
        custom_header: async () => {
          return {Authorization: await getAuthorization()}
        }
      },
      {
        name: "NoAuth",
        endpoint: apiEndpoint,
      }
    ]
  },
  Auth: {
    region: process.env.VUE_APP_AMP_region,
    userPoolId: process.env.VUE_APP_AMP_userPoolId,
    userPoolWebClientId: process.env.VUE_APP_AMP_userPoolWebClientId,
    identityPoolId: process.env.VUE_APP_AMP_identityPoolId
  }
});

const API_WEB_ANDROID = {
  get: async (apiName, path, init = {}) => {
    app.config.globalProperties.$tracking.event({
      eventName: 'api-get',
      category: 'api',
      event: {init, path}
    });
    return AwsAPI.get(apiName, path, init)
  },
  post: async (apiName, path, init = {}) => {
    app.config.globalProperties.$tracking.event({
      eventName: 'api-post',
      category: 'api',
      event: {init, path}
    });
    return AwsAPI.post(apiName, path, init)
  },
  put: async (apiName, path, init = {}) => {
    app.config.globalProperties.$tracking.event({
      eventName: 'api-put',
      category: 'api',
      event: {init, path}
    });
    return AwsAPI.put(apiName, path, init)
  },
  del: async (apiName, path, init = {}) => {
    app.config.globalProperties.$tracking.event({
      eventName: 'api-del',
      category: 'api',
      event: {init, path}
    });
    return AwsAPI.del(apiName, path, init)
  }
}

const API_IOS = {
  get: async (apiName, path, init = {}) => {
    const headers = await getHeaders(apiName)
    return new Promise((resolve, reject) => {
      HTTP.get(`${apiEndpoint}${path}`, init.queryStringParameters ?? {}, headers).then(response => {
        resolve(JSON.parse(response.data))
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    });
  },
  post: async (apiName, path, init = {}) => {
    const headers = await getHeaders(apiName)
    return new Promise((resolve, reject) => {
      HTTP.post(`${apiEndpoint}${path}`, init.body ?? {}, headers).then(response => {
        resolve(JSON.parse(response.data))
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    });
  },
  put: async (apiName, path, init = {}) => {
    const headers = await getHeaders(apiName)
    return new Promise((resolve, reject) => {
      HTTP.put(`${apiEndpoint}${path}`, init.body ?? {}, headers).then(response => {
        resolve(JSON.parse(response.data))
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    });
  },
  del: async (apiName, path, init = {}) => {
    const headers = await getHeaders(apiName)
    return new Promise((resolve, reject) => {
      HTTP.delete(`${apiEndpoint}${path}`, init.queryStringParameters ?? {}, headers).then(response => {
        resolve(JSON.parse(response.data))
      }).catch(error => {
        console.error(error)
        reject(error)
      })
    });
  }
}

export const apiConstants = {
  endpoint: apiEndpoint,
  getAuthorization: async () => await getAuthorization()
}

export const API = Capacitor.getPlatform() === 'ios' ? API_IOS : API_WEB_ANDROID