import * as Sentry from '@sentry/capacitor';
import {CaptureConsole as CaptureConsoleIntegration} from '@sentry/integrations';

export const trackingPlugin = {
  install(app) {
    app.config.globalProperties.$tracking = {
      setUser: () => { return null },
      clearUser: () => { return null },
      event: () => { return null }
    }
    app.config.globalProperties.$tracking.init = () => {
      Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        release: 'the-spott-users@' + process.env.VUE_APP_version,
        environment: process.env.VUE_APP_ENV_NAME,
        dist: process.env.VUE_APP_SENTRY_DIST,
        integrations: [
          new CaptureConsoleIntegration(
            {
              levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
            }
          )
        ]
      })
      app.config.globalProperties.$tracking.setUser = ({
                                                        email,
                                                        createdAt,
                                                        fullName,
                                                        cognitoId
                                                      }) => {
        Sentry.setUser({id: cognitoId, email})
        Sentry.setContext('user', {
          createdAt,
          fullName,
          email
        })
      }
      // Tracking Clear User
      app.config.globalProperties.$tracking.clearUser = () => {
        Sentry.configureScope(scope => scope.setUser(null))
      }
      // Tracking New Event
      app.config.globalProperties.$tracking.event = ({eventName, category, event = {}}) => {
        let sentryEvent = event
        if(typeof event === 'object' || Array.isArray(sentryEvent)) sentryEvent = JSON.stringify(sentryEvent)
        Sentry.addBreadcrumb({
            category: category,
            message: eventName,
            level: Sentry.Severity.Info,
            data: sentryEvent
        })
      }
    }
  }
}
